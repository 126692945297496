import React from 'react';
import Layout from '../components/layout/Layout';
// import Benefits from '../components/Benefits'
import { Helmet } from "react-helmet"
import Listing from '../components/Listing'
import {
  BadgeCheckIcon,
  BookmarkIcon,
  ClockIcon,
  GiftIcon,
  ReceiptRefundIcon,
  UsersIcon,
} from '@heroicons/react/outline'
import './careers.css'
import { Link } from 'gatsby';
import { Values } from '../components/values';
import { Typography,Stack } from '@mui/material';
const actions = [
  {
    title: 'Flexible Timing, Work from anywhere',
    href: '/',
    icon: ClockIcon,
    iconForeground: 'text-[#fff]',
    iconBackground: 'bg-[#6366f1]',
    cardText: "Work for work sake at your will and place. With speed & quality. We care for your outcomes.",
  },
  {
    title: 'Leave Benefits for important events',
    href: '/',
    icon: BookmarkIcon,
    iconForeground: 'text-[#fff]',
    iconBackground: 'bg-[#6366f1]',
    cardText: "We care for your life events. Wedding, Paternity, Maternity, Annual Leaves, Sick Leaves",
  },
  {
    title: 'Allowance',
    href: '/',
    icon: BadgeCheckIcon,
    iconForeground: 'text-[#fff]',
    iconBackground: 'bg-[#6366f1]',
    cardText: "We pay for your books, mobile, broadband",
  },
  {
    title: 'Meaningful Bonuses',
    href: '/',
    icon: GiftIcon,
    iconForeground: 'text-[#fff]',
    iconBackground: 'bg-[#6366f1]',
    cardText: "We pay meaningful performance bonuses.We pay vouchers during festivities.",
  },

]
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
export default function Example() {
  return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Careers at C4Scale</title>
          <meta name="description" content="Careers at C4Scale" />
        </Helmet>
    <Stack className='bg-indigo-600' sx={{padding:{xs:"60px 30px",md:"80px 150px"}}}>
      <h1 className='text-3xl md:text-4xl font-semibold text-[#fff]'>
      We are on this journey, together.<br/><span className='relative top-4'>
Together, We build.</span>
      </h1>
    </Stack>
    <div className="relative py-16 bg-white flex justify-center">
    
      <div className="mx-auto bg-[#f9fafb] lg:bg-transparent lg:pl-[5.5rem] lg:pr-4">
        <div className="lg:grid lg:grid-cols-12">
          <div className="relative z-10 lg:col-start-1 lg:row-start-1 lg:col-span-4 lg:py-16 lg:bg-transparent">
            <div className="absolute h-1/2 bg-gray-50 lg:hidden" aria-hidden="true" />
            <div className="max-w-md mx-auto px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0">
              <div className="aspect-w-10 aspect-h-6 sm:aspect-w-2 sm:aspect-h-1 lg:aspect-w-1 flex justify-center">
                <img
                  className="object-cover object-center rounded-3xl shadow-2xl h-[300px] lg:h-[inherit] lg:w-[70%]"
                  src="https://images.unsplash.com/photo-1507207611509-ec012433ff52?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=934&q=80"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="relative bg-[#f9fafb] lg:col-start-3 lg:row-start-1 lg:col-span-8 lg:rounded-3xl lg:grid lg:grid-cols-10 lg:items-center">
            <div className="hidden absolute inset-0 overflow-hidden rounded-3xl lg:block" aria-hidden="true">
              <svg
                className="absolute bottom-full left-full transform translate-y-1/3 -translate-x-2/3 xl:bottom-auto xl:top-0 xl:translate-y-0"
                width={404}
                height={300}
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-[#2E2E2E]" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={384} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
              </svg>
              <svg
                className="absolute top-full transform -translate-y-1/3 -translate-x-1/3 xl:-translate-y-1/2"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-[#2E2E2E]" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={384} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
              </svg>
            </div>
            <div className="relative max-w-md mx-auto py-12 px-4 space-y-6 sm:max-w-3xl sm:py-16 sm:px-6 lg:max-w-none lg:p-0 lg:col-start-4 lg:col-span-4 lg:ml-10">
              <h2 className="text-3xl font-extrabold text-[#2E2E2E]" id="join-heading">
                Join our team
              </h2>
              <p className="text-lg text-[#2E2E2E]">
              We build great innovative products, teams & businesses. We are a <strong> People First Company</strong> and are passionate about this commitment. Deep down in our hearts we understand that <strong>investing in people is invaluable.</strong> With our <strong>remote work policy, flexible timing, meaningful benefits</strong> our <strong>measure of people�s success is the contentment that Scalers enjoy</strong> ! See how we do it, Come, join our journey !
</p>
              
              <p className="block w-full py-3 px-5 text-center bg-white border border-transparent rounded-md shadow-md text-base font-medium text-[#2e2e2e] hover:bg-gray-50 sm:inline-block sm:w-auto">
              <Link to="#current">Send your CV to careers@c4scale.com</Link>
              </p>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    <h2 className="text-2xl lg:text-4xl font-semibold text-center mt-8" id='discover'>
    Our Benefits
      </h2>
    
      <div className="rounded-lg my-4 mx-[12px] sm:mx-[60px]  overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-3 sm:gap-px">
        {actions.map((action, actionIdx) => (
          <div
            key={action.title}
            className={classNames(
              actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
              actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
              actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
              actionIdx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
              'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 hover:bg-indigo-100'
            )}
          >
            <div>
              <span
                className={classNames(
                  action.iconBackground,
                  action.iconForeground,
                  'rounded-lg inline-flex p-3 ring-4 ring-white'
                )}
              >
                <action.icon className="h-6 w-6" aria-hidden="true" style={{ width: '50px', height: '50px' }} />
              </span>
            </div>
            <div className="mt-8">
              <h3 className="text-lg font-medium">
                
                  {/* Extend touch target to entire panel */}
                  <span className="absolute inset-0" aria-hidden="true" />
                  {action.title}
                
              </h3>
              <p className="mt-2 text-sm text-gray-600">
                {action.cardText}
              </p>
            </div>
          </div>
        ))}
      </div>
      <Values/>
    <section className="pad4" id="current">
      <h2 className="text-2xl sm:text-3xl font-extrabold mb-6" id="join-heading">
                Current Openings
              </h2>
      <Listing/>
    </section>
    {/* <Benefits/> */}  {/* Disabling Support for now */}
    </Layout>
  )
}
